import React from "react"

const Enrolments = () => {
	return (
		<section className="text-center bg-orange-400">
			<p className="leading-loose tracking-wide m-0">Accepting New Enrolments Now…</p>
		</section>
		);
}

export default Enrolments;